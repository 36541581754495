@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");

body {
  margin: 0;
  line-height: normal;
}
:root {
  --font-poppins: Poppins;
  --font-inter: Inter;
  
  --font-size-17xl: 36px;
  --font-size-21xl: 40px;
  --font-size-xl: 20px;
  --font-size-9xl: 28px;
  --font-size-5xl: 24px;
  
  --color-linen: #e2e9dd;
  --color-darkolivegreen: #155732;
  --color-honeydew: #f4ffeb;
  --color-white: #fff;
  --color-darkslategray: #07391d;
  --color-mediumseagreen: #44bd78;
  --color-black: #000;
  --color-mediumspringgreen: #28df77;
  
  --br-15xl: 34px;
  --br-lgi: 19px;
  --br-3xl: 22px;
  --br-13xl-2: 32.2px;
}
