.thirdbox,
.fourthbox,
.rectangle-main {
  position: absolute;
  top: 1220px;
  left: 170px;
  border-radius: var(--br-15xl);
  background-color: var(--color-honeydew);
  width: 1596px;
  height: 566px;
}
.fourthbox,
.rectangle-main {
  top: 2386px;
}
.rectangle-main {
  top: 4866px;
  background-color: var(--color-darkslategray);
  height: 704px;
}
.thickness-bar {
  left: 444px;
  background-color: var(--color-honeydew);
  width: 486px;
}
.thickness-bar,
.thickness-bar-progress,
.width-bar,
.width-bar-progress {
  position: absolute;
  top: 5306px;
  border-radius: var(--br-lgi);
  height: 38px;
}
.thickness-bar-progress {
  border: 0;
  background-color: var(--color-mediumspringgreen);
  left: 444px;
  width: 220px;
}
.width-bar,
.width-bar-progress {
  left: 1007px;
  background-color: var(--color-honeydew);
  width: 486px;
}
.width-bar-progress {
  background-color: var(--color-mediumspringgreen);
  width: 461px;
}
.ballisticord {
  font-weight: 600;
}
.span {
  font-size: var(--font-size-xl);
}
.ballisticord-trimme-txt-container {
  line-break: anywhere;
  width: 100%;
}
.ballisticord-trimme {
  position: absolute;
  top: 4979px;
  left: 477px;
  line-height: 55px;
  display: flex;
  align-items: center;
  width: 965px;
  height: 98px;
  font-size: var(--font-size-21xl);
}
.profiles,
.thickness {
  position: absolute;
  top: 5078px;
  left: 784px;
  font-size: var(--font-size-9xl);
  line-height: 42px;
  display: inline-block;
  color: var(--color-mediumspringgreen);
  width: 351px;
  height: 49px;
}
.thickness {
  top: 5232px;
  left: 511px;
}
.centertext,
.thickness-botton-txt {
  line-height: 34px;
  font-weight: 600;
  width: 563px;
  height: 57px;
}
.centertext {
  position: absolute;
  top: 5127px;
  left: 678px;
  display: inline-block;
}
.thickness-botton-txt {
  top: 5376px;
  left: 405px;
}
.thickness-botton-txt,
.width-botton-txt,
.width {
  position: absolute;
  display: inline-block;
}
.width {
  top: 5232px;
  left: 1074px;
  font-size: var(--font-size-9xl);
  line-height: 42px;
  color: var(--color-mediumspringgreen);
  width: 351px;
  height: 49px;
}
.width-botton-txt {
  top: 5376px;
  left: 968px;
  line-height: 34px;
  font-weight: 600;
  width: 563px;
  height: 57px;
}
.adobestock-265758062-icon,
.cord-2-icon {
  position: absolute;
  top: 2412px;
  left: 21.78px;
  width: 687.34px;
  height: 515px;
  object-fit: cover;
}
.adobestock-265758062-icon {
  top: 3037px;
  left: 170px;
  width: 1596px;
  height: 1102px;
}
.object,
.object-icon {
  position: absolute;
  top: 0;
  left: 0;
}
.object-icon {
  width: 368px;
  height: 630px;
  object-fit: cover;
}
.object {
  width: 100px;
  height: 100px;
}
.oneof4text,
.x {
  position: absolute;
  top: 402px;
  left: 40px;
  line-height: 34px;
  display: flex;
  align-items: flex-end;
  width: 287px;
  height: 164px;
}
.x {
  top: 324px;
  left: 34px;
  font-size: 80px;
  align-items: center;
  height: 120px;
}
.oneof4,
.twoof4 {
  position: absolute;
  top: 4188px;
  left: 170px;
  width: 368px;
  height: 630px;
  text-align: left;
}
.twoof4 {
  left: 579px;
}
.circle2nd {
  position: absolute;
  top: 4223px;
  left: 611px;
  border-radius: var(--br-13xl-2);
  background-color: var(--color-linen);
  width: 64px;
  height: 64px;
  opacity: 0.7;
  mix-blend-mode: normal;
}
.icon-vibrate,
.circle3rd {
  position: absolute;
  top: 4239px;
  left: 626px;
  width: 33px;
  height: 32px;
}
.circle3rd {
  top: 35px;
  left: 40px;
  border-radius: var(--br-13xl-2);
  background-color: var(--color-linen);
  width: 64px;
  height: 64px;
  opacity: 0.7;
  mix-blend-mode: normal;
}
.icon-noise {
  position: absolute;
  top: 46px;
  left: 53px;
  width: 38px;
  height: 43px;
}
.threeof4,
.fourof4 {
  position: absolute;
  top: 4188px;
  left: 1001px;
  width: 368px;
  height: 630px;
  text-align: left;
}
.fourof4 {
  left: 1397px;
}
.circle4th {
  position: absolute;
  top: 4223px;
  left: 1427px;
  border-radius: var(--br-13xl-2);
  background-color: var(--color-linen);
  width: 64px;
  height: 64px;
  opacity: 0.7;
  mix-blend-mode: normal;
}
.icon-timing,
.image1 {
  position: absolute;
  top: 4233px;
  left: 1441px;
  width: 36px;
  height: 45px;
}
.image1 {
  top: 0;
  left: 0;
  width: 1596px;
  height: 918px;
  object-fit: cover;
}
.firstbox,
.smallbox {
  position: absolute;
  top: 162px;
  left: 170px;
  width: 1596px;
  height: 920px;
}
.smallbox {
  top: 0;
  left: 0;
  border-radius: var(--br-3xl);
  background: linear-gradient(
    135.82deg,
    rgba(255, 255, 255, 0.54),
    rgba(255, 255, 255, 0.22)
  );
  backdrop-filter: blur(13.59px);
  width: 256px;
  height: 284px;
}
.golf-courses {
  position: absolute;
  top: 143px;
  left: 41px;
  line-height: 34px;
  display: inline-block;
  width: 174px;
  height: 66px;
}
.group {
  position: absolute;
  top: 3673px;
  left: 416px;
  width: 256px;
  height: 284px;
  color: var(--color-black);
  font-family: var(--font-inter);
}
.icon-golf {
  position: absolute;
  top: 3717px;
  left: 516px;
  width: 56.2px;
  height: 66.7px;
}
.municipal {
  position: absolute;
  top: 143px;
  left: 31px;
  line-height: 34px;
  display: inline-block;
  width: 194px;
  height: 120px;
}
.group1,
.icon-park {
  position: absolute;
  top: 35px;
  left: 100px;
  width: 56.17px;
  height: 70.68px;
}
.group1 {
  top: 3673px;
  left: 699px;
  width: 256px;
  height: 284px;
  color: var(--color-black);
  font-family: var(--font-inter);
}
.commercial {
  position: absolute;
  top: 143px;
  left: 30px;
  line-height: 34px;
  display: inline-block;
  width: 196px;
  height: 120px;
}
.group2,
.icon-contractor {
  position: absolute;
  top: 44px;
  left: 101px;
  width: 54.7px;
  height: 65.6px;
}
.group2 {
  top: 3673px;
  left: 982px;
  width: 256px;
  height: 284px;
  color: var(--color-black);
  font-family: var(--font-inter);
}
.group3,
.icon-house {
  position: absolute;
  top: 48px;
  left: 95px;
  width: 66.61px;
  height: 53.9px;
}
.group3 {
  top: 3673px;
  left: 1265px;
  width: 256px;
  height: 284px;
  color: var(--color-black);
  font-family: var(--font-inter);
}
.ballisticord-trimme1 {
  position: absolute;
  top: 3308px;
  left: 630px;
  line-height: 55px;
  display: inline-block;
  width: 677px;
  height: 276px;
  font-size: var(--font-size-21xl);
}
.span-text {
  font-weight: 500;
}
.bold-text {
  color: var(--color-mediumseagreen);
}
.fourthbox-content,
.oriented-polymers-ar,
.thirdbox-content {
  position: absolute;
  left: 301px;
  color: var(--color-darkslategray);
  text-align: left;
}
.thirdbox-content {
  top: 1321px;
  font-size: var(--font-size-17xl);
  line-height: 50px;
  display: inline-block;
  width: 1335px;
  height: 186px;
}
.fourthbox-content,
.oriented-polymers-ar {
  top: 1922px;
  font-size: var(--font-size-9xl);
  line-height: 40px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 733px;
  height: 306px;
}
.fourthbox-content {
  top: 2516px;
  left: 881px;
}
.grass-clippings-icon {
  position: absolute;
  top: 1664px;
  left: 1140px;
  width: 1093.11px;
  height: 642px;
  object-fit: cover;
}
.longer-life-expectan {
  position: absolute;
  top: 1559px;
  left: 424px;
  font-size: var(--font-size-21xl);
  line-height: 50px;
  display: flex;
  color: var(--color-darkolivegreen);
  text-align: left;
  align-items: center;
  width: 291px;
  height: 105px;
}
.icon-calendar,
.oval-icon,
.oval-icon1,
.oval-icon2 {
  position: absolute;
  top: 1569px;
  left: 306px;
  width: 64px;
  height: 64px;
}
.icon-calendar,
.oval-icon1,
.oval-icon2 {
  left: 775px;
}
.icon-calendar,
.oval-icon2 {
  left: 1239px;
}
.icon-calendar {
  top: 1591.5px;
  left: 328.5px;
  width: 55.4px;
  height: 56px;
}
.increased-durability {
  position: absolute;
  top: 1559px;
  left: 900px;
  font-size: var(--font-size-21xl);
  line-height: 50px;
  display: flex;
  color: var(--color-darkolivegreen);
  text-align: left;
  align-items: center;
  width: 291px;
  height: 105px;
}
.icon-chart {
  position: absolute;
  top: 1585.5px;
  left: 799.5px;
  width: 60.11px;
  height: 64.3px;
}
.lighter {
  margin: 0;
}
.lighter-than-nyl-container {
  position: absolute;
  top: 1559px;
  left: 1345px;
  font-size: var(--font-size-21xl);
  line-height: 50px;
  display: flex;
  color: var(--color-darkolivegreen);
  text-align: left;
  align-items: center;
  width: 291px;
  height: 105px;
}
.ballisticord2,
.icon-weight-v2 {
  position: absolute;
  top: 1578.5px;
  left: 1267.5px;
  width: 44.6px;
  height: 71.7px;
}
.ballisticord2 {
  top: 459px;
  left: 0;
  font-size: 14px;
  line-height: 26px;
  display: inline-block;
  width: 1111px;
  height: 60px;
}
.email-box {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-lgi);
  background-color: var(--color-honeydew);
  width: 448px;
  height: 91px;
}
.email-text {
  position: absolute;
  top: 16px;
  left: 83px;
  line-height: 26px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 337px;
  height: 60px;
  color: var(--color-darkolivegreen);
}
.icon-email {
  position: absolute;
  top: 34px;
  left: 39px;
  width: 23.9px;
  height: 24.48px;
}
.email,
.phone-box {
  position: absolute;
  top: 129px;
  left: 199px;
  width: 448px;
  height: 91px;
}
.phone-box {
  top: 0;
  left: 0;
  border-radius: var(--br-lgi);
  background-color: var(--color-honeydew);
  width: 252px;
}
.phone-text {
  position: absolute;
  top: 16px;
  left: 70px;
  line-height: 26px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 163px;
  height: 60px;
}
.icon-phone,
.phone {
  position: absolute;
  top: 34px;
  left: 40px;
  width: 14.5px;
  height: 25.2px;
}
.phone {
  top: 129px;
  left: 661px;
  width: 252px;
  height: 91px;
}
.for-more-information {
  position: absolute;
  top: 0;
  left: 1px;
  font-size: var(--font-size-21xl);
  line-height: 60px;
  font-weight: 600;
  display: inline-block;
  width: 1111px;
  height: 119px;
}
.footer {
  position: absolute;
  top: 5743px;
  left: 404px;
  width: 1112px;
  height: 519px;
  font-size: var(--font-size-xl);
  color: var(--color-darkolivegreen);
}
.secondbox {
  position: absolute;
  top: 620px;
  left: 283px;
  border-radius: var(--br-15xl);
  background-color: var(--color-linen);
  width: 789px;
  height: 537px;
}
.secondbox-content-text {
  color: var(--color-darkslategray);
}
.secondbox-content,
.first-heading {
  margin: 0;
  position: absolute;
  font-family: inherit;
  text-align: left;
  display: inline-block;
}
.secondbox-content {
  top: 709px;
  left: 395px;
  font-size: var(--font-size-17xl);
  line-height: 50px;
  width: 608px;
  height: 300px;
  color: var(--color-mediumseagreen);
}
.first-heading {
  top: 316px;
  left: 382px;
  font-size: 60px;
  line-height: 70px;
  font-weight: 600;
  width: 634px;
  height: 254px;
}
.cord-copy-icon {
  position: absolute;
  top: 518.35px;
  left: 382.12px;
  width: 179.89px;
  height: 179.84px;
  object-fit: cover;
}
.ballisticord-logo,
.box-contactus {
  position: absolute;
  top: 41px;
  left: 283px;
  width: 376px;
  height: 90px;
}
.box-contactus {
  top: 69px;
  left: 1510px;
  border-radius: 8px;
  background-color: var(--color-honeydew);
  width: 170px;
  height: 57px;
}
.contact-us {
  text-decoration: none;
  position: absolute;
  top: 80px;
  left: 1514px;
  font-size: 18px;
  line-height: 44px;
  font-weight: 600;
  color: var(--color-darkolivegreen);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 162px;
  height: 34px;
}
.ballisticord-website {
  position: relative;
  background-color: var(--color-linen);
  width: 1920px;
  height: 6262px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
